.headLogo{
    height: 90px;
    width: auto;
}
.navLinkItem{
    margin: 6px 6px;
   color:#fff;
    border-radius: 4px;
    transition: 0.3s ease-in-out;    
}

.blueBg{
    background: hsla(214, 92%, 47%, 1);
    background: linear-gradient(90deg, #4C41C9 0%, rgb(35, 57, 184) 100%);    
    background: -moz-linear-gradient(90deg, #4C41C9 0%, rgb(35, 57, 184) 100%);    
    background: -webkit-linear-gradient(90deg, #4C41C9 0%, rgb(35, 57, 184) 100%);    
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#0968E5", endColorstr="#091970", GradientType=1 );
}

.navLinkItem:hover{    
    color: #e7e7e7;
    /* box-shadow: 0px 2px 2px #4C41C9 ; */
}


.titleSanstha{
    font-size: 16px;
    font-weight: bolder;
    color: #E63206;
}
.titleHeader{
    font-size: 32px;
    font-weight: 500;
    color: #5B2C6F;
    font-family: "Libre Baskerville";
}

.linkIcon{
    color: #e9e9e9;   
}

@media screen and (width < 720px) {
    .headLogo{
        height: 70px;
        width: auto;
    }

    .titleHeader{
        font-size: 18px;        
        font-weight: bold;
        color: #5B2C6F;
    }
  }